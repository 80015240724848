import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from "../components/layout"
import '../styles/index.css';

function Index() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Gatsby + Node.js (TypeScript) API</title>
      </Helmet>
      <div className="container mx-auto">
          <div className="max-w-2xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
              <img className="object-cover w-full h-64"
                   src="https://images.unsplash.com/photo-1550439062-609e1531270e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                   alt="Article" />

                  <div className="p-6">
                      <div>
                          <span
                              className="text-xs font-medium text-blue-600 uppercase dark:text-blue-400">Product</span>
                          <a href="https://nba.com"
                             className="block mt-2 text-2xl font-semibold text-gray-800 dark:text-white hover:text-gray-600 hover:underline">I
                              Built A Successful Blog In One Year</a>
                          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit. Molestie parturient et sem ipsum volutpat vel. Natoque sem et
                              aliquam mauris egestas quam volutpat viverra. In pretium nec senectus erat. Et malesuada
                              lobortis.</p>
                      </div>

                      <div className="mt-4">
                          <div className="flex items-center">
                              <div className="flex items-center">
                                  <img className="object-cover h-10 rounded-full"
                                       src="https://images.unsplash.com/photo-1586287011575-a23134f797f9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=48&q=60"
                                       alt="Avatar" />
                                      <a href="https://nba.com" className="mx-2 font-semibold text-gray-700 dark:text-gray-200">Jone
                                          Doe</a>
                              </div>
                              <span className="mx-1 text-xs text-gray-600 dark:text-gray-300">21 SEP 2015</span>
                          </div>
                      </div>
                  </div>
          </div>
          <h1 className="text-red-900">Gatsby + Node.js (TypeScript) API</h1>
          <h2>
              Deployed with{' '}
              <a
                  href="https://vercel.com/docs"
                  target="_blank"
                  rel="noreferrer noopener"
              >
                  Vercel
              </a>
              !
          </h2>
          <p>
              <a
                  href="https://github.com/vercel/vercel/tree/master/examples/gatsby"
                  target="_blank"
                  rel="noreferrer noopener"
              >
                  This project
              </a>{' '}
              is a <a href="https://www.gatsbyjs.org/">Gatsby</a> app with two
              directories, <code>/src</code> for static content and <code>/api</code>{' '}
              which contains a serverless{' '}
              <a href="https://nodejs.org/en/">Node.js (TypeScript)</a> function. See{' '}
              <a href="/api/date">
                  <code>api/date</code> for the Date API with Node.js (TypeScript)
              </a>
              .
          </p>
          <br />
          <h2>The date according to Node.js (TypeScript) is:</h2>
          <p>{date ? date : 'Loading date...'}</p>
      </div>
    </Layout>
  );
}

export default Index;
