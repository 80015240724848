import React from "react"
import "../styles/style.css"
// import { Helmet } from 'react-helmet';

const Layout = ({children}) => {

    return (
        <div id="wrap">
            {children}
        </div>
    )
}

export default Layout